/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Box } from 'theme-ui';
import TextFeature from 'components/text-feature';
// import Img from 'gatsby-image';
// import { useStaticQuery, graphql } from 'gatsby';

const data = [
    // {        
    //     title: ' REGULAMIN ',
    //     subTitle: '',
    //     description: '',
    //     btnName: 'PDF',
    //     btnURL: 'http://archiwum.pttk.pl/pttk/odznaki/ro_otp.pdf',
    // },
    {        
        title: 'Polityka prywatności aplikacji mobilnych',
        description: 'Niniejszy dokument opisuje politykę prywatności aplikacji umieszczonych na platformach w sklepach App Store \n\
        oraz Google Play na platformie mobilnej iOS i Android zwanych dalej aplikacjami.',
    },
    {        
      description: 'W zależności od aplikacji lub wersji aplikacji połączenie funkcji może być różne.',       
    },
    { 
      description: '1. Aplikacja może mieć dostęp do lokalizacji urządzenia na podstawie sygnału GPS i sieci. Informacja ta jest wykorzystywana do nawigacji GPS po trasach i do obiektów zawartych w aplikacji mobilnej lub automatycznymi powiadomieniami bazującymi na geolokalizacji.',
    },
    {        
      description: '2. Aplikacja może mieć dostęp do Bluetooth w celu komunikacji z urządzeniami zewnętrznymi BLE (beacony) wywołującymi interakcję.',
    },
    {        
      description: '3. Aplikacja może mieć dostęp do aparatu w urządzeniu w celu: \n\
      a. skanowania kodów QR \n\
      b. robienia „selfie” zapisywania go lub udostępniania przez użytkownika.',
    },
    {        
      description: '4. Aplikacja może umożliwić wywołanie usługi wybierania i dzwonienia pod wybrany numer telefonu zawarty w aplikacji.',
    },
    {        
      description: '5. Aplikacja może umożliwiać wywoływanie adresów email w celu przesyła emaila przez domyślną aplikację pocztową, adresy mogą znajdować się w treści aplikacji.',
    },
    {        
      description: '6. Aplikacja może umożliwiać wywoływanie adresów zewnętrznych stron internetowych zawartych w treści aplikacji.',
    },
    {        
      description: '7. Aplikacja nie zbiera, nie wysyła i nie przekazuje żadnych danych osobom trzecim, komunikuje się jedynie z API strony służącej do zarządzania treścią.',
    },
    {        
      description: '8. Niektóre funkcje mogą wymagać założenia konta lub logowania, warunki przechowywania i przetwarzania informacji związanych logowaniem są dostępne każdorazowo w regulaminie aplikacji.',
    },
    {      
      description: 'Global-Lab Sp. z o.o. ul. Lustrzana 4, 01,342 Warszawa, NIP: 522-300-72-72, biuro@global-lab.pl, +48 531-531-456 \n\
                                          KRS: 0000468992, Bank Millenium: PL 52 1160 2202 0000 0002 6919 8536', 
    }
  ];

  

export default function PrivacyPolicy() {


  return (
    <section id='terms' sx={{ variant: 'section.securePayment' }}>
      <Box sx={styles.bgOverlay} />
      <Container sx={styles.containerBox}>
       
        <Box sx={styles.contentBox}>
            {data.map((item) => (
                <TextFeature
                  subTitle={item.subTitle}
                  title={item.title}
                  underTitle={item.underTitle}
                  description={item.description}
                  btnName={item.btnName}
                  // null
                  btnURL={item.btnURL}
                  // null
                  // data-html="true"
                />
            ))}          
        </Box>

      </Container>
    </section>
  );
}

const styles = {
  bgOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    zIndex: -1,
    width: [
      '100%',
      null,
      null,
      'calc(50% + 400px)',
      'calc(50% + 480px)',
      'calc(50% + 570px)',
      null,
      'calc(50% + 625px)',
    ],
    // backgroundImage: `url(${PaymentPattern})`,
    // backgroundRepeat: `no-repeat`,
    // backgroundPosition: 'top left',
    // backgroundSize: 'cover',
    // backgroundColor: 'white',
    // background: 'linear-gradient(-45deg, rgba(42,72,125, 0.3) 25%, transparent 25%, transparent 50%, rgba(42,72,125, 0.3) 50%, rgba(42,72,125, 0.3) 75%, transparent 75%, transparent)',
    // backgroundColor: 'primary',
  },
  containerBox: {
    mt: 10,
    display: 'flex',
    alignItems: 'flex-start',
    // justifyContent: 'space-between',
    flexWrap: ['wrap', null, null, 'nowrap'],
  },
  thumbnail: {
    mb: -2,
    ml: [-4, '-40px', null, null, 0],
    order: [2, null, null, 0],
    pr: [6, null, 7, 0],
    width: [
      'calc(100% + 20px)',
      'calc(100% + 40px)',
      null,
      370,
      460,
      570,
      null,
      680,
    ],
  },
  contentBox: {
    // width: ['100%', 420, 480, 380, 500, 570],
    width: '100%',
    mx: 'auto',
    flexShrink: 0,
    // textAlign: ['center', null, null, 'left'],
    textAlign: 'center',
    pt: [0, null, null, 4, '50px', null, 4, '80px'],
    pl: [0, null, null, 40, '90px'],
    pb: [7, null, null, 9],
    pr: [0, null, null, null, null, 6],
  },
};
