import React from "react";
import { StickyProvider } from "contexts/app/app.provider";
// import SEO from "components/seo";
import Layout from "components/layout";

// import Regulamin from "sections/package";
// import Faq from "sections/faq";
// import Terms from "sections/terms";
import PrivacyPolicy from "sections/privacy-policy";

// Load other package css file
// import "react-multi-carousel/lib/styles.css";
// import "react-modal-video/css/modal-video.min.css";
// import "rc-drawer/assets/index.css";
// import "mapbox-gl/dist/mapbox-gl.css";

export default function privacyPolicyPage() {
  return (
    <StickyProvider>
      <Layout>
        {/* <SEO title="ROWEROWY SZLAK 100 lecia BITWY WARSZAWSKIEJ 1920" /> */}
        
        {/* <Faq/> */}
        <PrivacyPolicy/>



        {/* <Faq /> */}
      </Layout>
    </StickyProvider>
  );
}
